/* eslint-disable */
import React from 'react';

export default function AutoHeightFrame(props){
	return (<iframe
			className="overflow-y-visible border-0 self-center mt-[80px] absolute top-0 left-0 w-full h-full"
			src={props.src}
			style={{marginTop: "80px"}}
	/>);
}
