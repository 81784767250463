/* eslint-disable */
import React from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout/Layout';
import AutoHeightFrame from '../components/AutoHeightFrame';

export default function Booking(){
	const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          formUrl
        }
      }
    }
  `);
	return (
			<Layout>
				<Helmet>
					<meta charSet="utf-8"/>
					<title>Buchung</title>
				</Helmet>
				<section className="pt-10 md:pt-20 text-center">
					<AutoHeightFrame src={data.site.siteMetadata.formUrl}/>
				</section>
			</Layout>
	);
}
